<template>
  <v-container fluid class="white" style="min-height:50vh;">
    <section v-if="isLoadingCompanyInformation">
      <v-row v-for="skeleton in repeatSkeleton" :key="skeleton">
        <v-col cols="12" md="3">
          <v-skeleton-loader
            type="list-item"
          ></v-skeleton-loader>
        </v-col>
        <v-col cols="12" md="5">
          <v-skeleton-loader
            type="image"
            height="35"
          ></v-skeleton-loader>
        </v-col>
        <v-col cols="12" md="4">
          <v-skeleton-loader
            type="button"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </section>
    <section v-else class="mt-4">
      <v-form ref="defaultSelected" v-model="valid" lazy-validation>
        <v-row>
          <v-col cols="12" sm="3" class="pt-5">
            <h4 class="ml-12">Kode Oracle : </h4>
          </v-col>
          <v-col cols="12" sm="6" md="7">
            <v-text-field
              readonly
              :value="form.oracleId || '-'"
              dense
              outlined
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="3" class="pt-5">
            <h4 class="ml-12">Integrasi Oracle : </h4>
          </v-col>
          <v-col cols="12" sm="6" md="7">
            <v-select
              v-model="statusApi"
              dense
              outlined
              hide-details
              :items="statusList"
              item-value="key"
              item-text="value"
              :disabled="edit === 'detail'"
            >
            </v-select>
          </v-col>
        </v-row>
        <v-row class="pt-5">
          <v-col cols="12" sm="3" class="pt-5">
            <h4 class="ml-12">Kode Area : </h4>
          </v-col>
          <v-col cols="12" sm="6" md="7">
            <v-autocomplete
              v-model="defaultAreaCode.id"
              dense
              outlined
              :loading="isLoadingCodeArea"
              :items="areaCodeList"
              item-value="id"
              item-text="name"
              @change="onChangeAreaCode(_, $event)"
              :rules="rules.areaCodeRule"
              :disabled="edit === 'detail'"
            >
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="3" class="pt-5">
            <h4 class="ml-12">Grup Shipper : </h4>
          </v-col>
          <v-col cols="12" sm="6" md="7">
            <v-select
              v-model="statusIsGroup"
              dense
              outlined
              hide-details
              :items="statusGroup"
              item-value="key"
              item-text="value"
              :disabled="edit === 'detail'"
            >
            </v-select>
          </v-col>
        </v-row>
        <v-row class="pt-5">
          <v-col cols="12" sm="3" class="pt-5">
            <h4 class="ml-12">Kategori Shipper : </h4>
          </v-col>
          <v-col cols="12" sm="6" md="7">
            <v-autocomplete
              v-model="defaultCategoryShipper.id"
              dense
              outlined
              :loading="isLoadingCategoryShipper"
              :items="categoryShipperList"
              item-value="id"
              item-text="name"
              @change="onChangeCategoryShipper(_, $event)"
              :rules="rules.categoryShipperRule"
              :disabled="edit === 'detail'"
            >
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="3" class="pt-5">
            <h4 class="ml-12">Jenis Shipper : </h4>
          </v-col>
          <v-col cols="12" sm="6" md="7">
            <v-autocomplete
              v-model="defaultTypeShipper.id"
              dense
              outlined
              :loading="isLoadingTypeShipper"
              :items="typeShipperList"
              item-value="id"
              item-text="name"
              @change="onChangeTypeShipper(_, $event)"
              :rules="rules.typeShipperRule"
              :disabled="edit === 'detail'"
            >
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="3" class="pt-5">
            <h4 class="ml-12">Sales : </h4>
          </v-col>
          <v-col cols="12" sm="6" md="7">
            <v-autocomplete
              v-model="defaultSales.id"
              dense
              outlined
              :loading="isLoadingMasterSales"
              :items="masterSalesList"
              item-value="id"
              item-text="name"
              @change="onChangeMasterSales(_, $event)"
              :rules="rules.SalesRule"
              :disabled="edit === 'detail'"
            >
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-col v-if="myUserInfo.roleName.toLowerCase() === 'super_admin'" cols="1" class="ml-md-auto ma-6">
          <v-btn
            class="mt-1"
            color="primary"
            @click="updateStatus"
            :disabled="edit === 'detail'"
          >
            Update
          </v-btn>
        </v-col>
        <v-row>
        </v-row>
      </v-form>
    </section>
  </v-container>
</template>

<script>
export default {
  name: 'oracle-integration-setting',
  props: {
    isLoadingCompanyInformation: {
      type: Boolean,
      default: () => false,
    },
    readonly: {
      type: Boolean,
      default: () => true,
    },
    form: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      valid: false,
      repeatSkeleton: 5,
      statusApi: '',
      statusIsGroup: '',
      statusList: this.$_strings.masterShipper.statusActivated,
      statusGroup: this.$_strings.masterShipper.statusGroup,
      isLoadingCodeArea: false,
      isLoadingCategoryShipper: false,
      isLoadingTypeShipper: false,
      isLoadingMasterSales: false,
      areaCodeList: [],
      categoryShipperList: [],
      typeShipperList: [],
      masterSalesList: [],
      defaultAreaCode: {
        id: '',
        name: '',
      },
      defaultSales: {
        id: '',
        name: '',
      },
      defaultCategoryShipper: {
        id: '',
        name: '',
      },
      defaultTypeShipper: {
        id: '',
        name: '',
      },
      rules: {
        areaCodeRule: [
          (v) => !!v || 'Kode area harus diisi',
        ],
        SalesRule: [
          (v) => !!v || 'Sales harus diisi',
        ],
        categoryShipperRule: [
          (v) => !!v || 'Kategori Shipper harus diisi',
        ],
        typeShipperRule: [
          (v) => !!v || 'Jenis Shipper harus diisi',
        ],
      },
    };
  },
  mounted() {
    this.generateCodeAreaList();
    this.generateMasterSalesList();
    this.generateShipperCategoryList();
    this.generateShipperTypeList();
    this.setStatus();
  },
  computed: {
    myUserInfo() {
      return this.$store.state.user.myUserInfo;
    },
    path() {
      const [,, path] = this.$route.path.split('/');
      return path;
    },
    edit() {
      const [,,, edit] = this.$route.path.split('/');
      return edit;
    },
  },
  methods: {
    onChangeTypeShipper(_, event) {
      this.defaultTypeShipper.id = event;
    },
    onChangeCategoryShipper(_, event) {
      this.defaultCategoryShipper.id = event;
    },
    onChangeAreaCode(_, event) {
      this.defaultAreaCode.id = event;
    },
    onChangeMasterSales(_, event) {
      this.defaultSales.id = event;
    },
    setStatus() {
      this.statusApi = this.form.isOracleIntegrated;
      this.statusIsGroup = this.form.isGroup;
    },
    async updateStatus() {
      if (!this.$refs.defaultSelected.validate()) {
        return;
      }
      const companyId = this.form.id;
      const event = this.statusApi;
      const areaCodeId = this.defaultAreaCode.id;
      const isGroup = this.statusIsGroup;
      const shipperCategoryId = this.defaultCategoryShipper.id;
      const shipperGroupId = this.defaultTypeShipper.id;
      const salesId = this.defaultSales.id;
      this.$root.$loading.show();
      try {
        await this.$_services.masterShipper.updateAreaCode(companyId, event, areaCodeId, isGroup, shipperCategoryId, shipperGroupId, salesId);
        this.$dialog.notify.success('Berhasil');
        this.$emit('fetchCompanyInformation');
      } finally {
        this.$root.$loading.hide();
      }
    },
    async generateCodeAreaList() {
      const filter = {
        page: 0,
        size: 150,
      };
      this.isLoadingCodeArea = true;
      this.$_services.areaCode.fetchList(filter)
        .then((result) => {
          this.areaCodeList = result.data.contents;
          result.data.contents.forEach((element) => {
            if (element.id === this.form.areaCodeId) {
              this.defaultAreaCode = {
                id: element.id,
                name: element.name,
              };
            }
          });
        }).finally(() => {
          this.isLoadingCodeArea = false;
        });
    },
    async generateMasterSalesList() {
      const filter = {
        page: 0,
        size: 150,
      };
      this.isLoadingMasterSales = true;
      this.$_services.masterSales.getListMasterSales(filter)
        .then((result) => {
          result.data.contents.forEach((element) => {
            this.masterSalesList.push({
              id: element.id,
              name: `${element.salesCode} - ${element.name}`,
            });
            if (element.id === this.form.salesId) {
              this.defaultSales = {
                id: element.id,
                name: element.name,
              };
            }
          });
        }).finally(() => {
          this.isLoadingMasterSales = false;
        });
    },
    async generateShipperCategoryList() {
      const filter = {
        page: 0,
        size: 150,
      };
      this.isLoadingCategoryShipper = true;
      this.$_services.masterShipper.getListShipperCategory(filter)
        .then((result) => {
          this.categoryShipperList = result.data.contents;
          result.data.contents.forEach((element) => {
            if (element.id === this.form.companyCategoryId) {
              this.defaultCategoryShipper = {
                id: element.id,
                name: element.name,
              };
            }
          });
        }).finally(() => {
          this.isLoadingCategoryShipper = false;
        });
    },
    async generateShipperTypeList() {
      const filter = {
        page: 0,
        size: 150,
      };
      this.isLoadingTypeShipper = true;
      this.$_services.masterShipper.getListShipperType(filter)
        .then((result) => {
          this.typeShipperList = result.data.contents;
          result.data.contents.forEach((element) => {
            if (element.id === this.form.companyGroupId) {
              this.defaultTypeShipper = {
                id: element.id,
                name: element.name,
              };
            }
          });
        }).finally(() => {
          this.isLoadingTypeShipper = false;
        });
    },
  },
};
</script>
