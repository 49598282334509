<template>
  <v-container fluid class="white" style="min-height:50vh">
    <section v-if="isLoadingCompanyInformation">
      <v-row v-for="skeleton in 1" :key="skeleton">
        <v-col cols="12" md="3">
          <v-skeleton-loader
            type="list-item"
          ></v-skeleton-loader>
        </v-col>
        <v-col cols="12" md="5">
          <v-skeleton-loader
            type="image"
            height="35"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </section>
    <section v-else class="mt-4">
      <v-row>
        <v-col cols="12" sm="2" class="d-flex align-center">
          <h4 class="ml-12">Status : </h4>
        </v-col>
        <v-col cols="12" sm="6" md="7">
          <v-select
            v-model="statusApi"
            dense
            outlined
            hide-details
            :items="statusList"
            item-value="key"
            item-text="value"
            :disabled="edit === 'detail' || myUserInfo.roleName.toLowerCase() !== 'super_admin'"
          >
          </v-select>
        </v-col>
      </v-row>
      <v-row v-if="form.isOpenApi === true && myUserInfo.roleName.toLowerCase() === 'super_admin'">
        <v-col cols="12" sm="2" class="d-flex align-center">
          <h4 class="ml-12">API Key : </h4>
        </v-col>
        <v-col cols="12" sm="6" md="7">
          <v-text-field
            :value="form.clientKey ? form.clientKey : '-'"
            dense
            outlined
            hide-details
            disabled>
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="auto">
          <v-btn
            class="mt-1"
            color="primary"
            @click="generateApiKey(form.id)"
            :disabled="edit === 'detail'"
          >
            Generate
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="form.isOpenApi === true && myUserInfo.roleName.toLowerCase() === 'super_admin' && path==='rekanan'">
        <v-col cols="12" sm="2" class="d-flex align-center">
          <h4 class="ml-12">URL Callback : </h4>
        </v-col>
        <v-col cols="12" sm="6" md="7">
          <v-text-field
            v-model="callbackUrl"
            dense
            outlined
            hide-details
            :disabled="edit === 'detail'"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-col v-if="myUserInfo.roleName.toLowerCase() === 'super_admin'" cols="1" class="ml-md-auto ma-6">
        <v-btn
          class="mt-1"
          color="primary"
          @click="updateStatus"
          :disabled="edit === 'detail'"
        >
          Update
        </v-btn>
      </v-col>
      <v-row>
      </v-row>
    </section>
  </v-container>
</template>

<script>
export default {
  name: 'open-api',
  props: {
    isLoadingCompanyInformation: {
      type: Boolean,
      default: () => false,
    },
    readonly: {
      type: Boolean,
      default: () => true,
    },
    form: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      listAdminInformation: [],
      statusApi: '',
      urlCallback: '',
      apiKey: '',
      statusList: this.$_strings.masterShipper.statusActivated,
    };
  },
  mounted() {
    this.setStatus();
  },
  computed: {
    myUserInfo() {
      return this.$store.state.user.myUserInfo;
    },
    path() {
      const [,, path] = this.$route.path.split('/');
      return path;
    },
    edit() {
      const [,,, edit] = this.$route.path.split('/');
      return edit;
    },
  },
  methods: {
    setStatus() {
      this.statusApi = this.form.isOpenApi;
      this.urlCallback = this.form.urlCallback;
    },
    async updateStatus() {
      const companyId = this.form.id;
      const event = this.statusApi;
      const url = this.callbackUrl;
      this.$root.$loading.show();
      try {
        let service;
        if (this.path === 'customer') service = this.$_services.masterShipper.updateStatusApi;
        if (this.path === 'rekanan') service = this.$_services.masterPatner.updateStatusApi;
        await service(companyId, event, url);
        this.$dialog.notify.success('Berhasil');
        this.$emit('fetchCompanyInformation');
      } finally {
        this.$root.$loading.hide();
      }
    },
    async generateApiKey(item) {
      this.$root.$loading.show();
      try {
        await this.$_services.masterShipper.generateOpenAPI(item);
        this.$emit('fetchCompanyInformation');
        this.$dialog.notify.success('Berhasil');
      } finally {
        this.$root.$loading.hide();
      }
    },
  },
};
</script>
