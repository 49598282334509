<template>
  <v-container fluid class="white">
    <section v-if="isLoading">
      <v-row v-for="skeleton in 3" :key="skeleton">
        <v-col cols="12" md="3">
          <v-skeleton-loader
            type="list-item"
          ></v-skeleton-loader>
        </v-col>
        <v-col cols="12" md="9">
          <v-skeleton-loader
            type="image"
            height="35"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row v-for="skeleton in 3" :key="skeleton * 5">
        <v-col class="pa-0" offset-md="3" cols="12" md="8">
          <v-skeleton-loader
            type="list-item"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row v-for="skeleton in 3" :key="skeleton * 4">
        <v-col cols="12" md="3">
          <v-skeleton-loader
            type="list-item"
          ></v-skeleton-loader>
        </v-col>
        <v-col cols="12" md="9">
          <v-skeleton-loader
            type="image"
            height="35"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </section>
    <v-form
      v-else
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <common-text-input
        :inputLabel="$_strings.companyInformation.COMPANY_NAME_INPUT_LABEL"
        v-model="form.name"
        :readOnly=readonly
      />
      <v-form
        v-model="validAbbreviation"
      >
        <v-row class="px-3">
          <v-col cols="12" sm="2">
            <p class="body-2">
              {{$_strings.companyInformation.ALIAS_INPUT_LABEL}}
              <span class="error--text d-inline">*</span>
            </p>
          </v-col>
          <v-col cols="12" sm="6" :md="path === 'edit' ? 8 : 10">
            <v-text-field
              v-model="form.abbreviation"
              required
              :clearable="!path === 'detail'"
              :disabled="path === 'detail'"
              solo
              :maxLength="5"
              outlined
              :rules="rules.abbreviation"
              dense
              flat
            ></v-text-field>
          </v-col>
          <v-col cols="4" md="2" v-if="path === 'edit'">
            <v-btn
              color="primary"
              :disabled="oldAbbreviation === form.abbreviation || !validAbbreviation"
              @click="updateAbbreviation"
            >
              {{$_strings.common.EDIT}}
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
      <common-text-input
        :inputLabel="$_strings.companyInformation.COMPANY_ADDRESS_INPUT_LABEL"
        v-model="form.address.address1"
        isTextArea
        :readOnly=readonly
      />
      <v-row no-gutters>
        <v-col offset-md="2">
          <common-autocomplete-input
            :inputLabel="$_strings.locations.PROVINCE_INPUT_LABEL"
            v-model="form.address.provinceId"
            :label="$_strings.locations.PROVINCE_INPUT_LABEL"
            item-text="text"
            item-value="value"
            :items="provinceList"
            :readOnly=readonly
          />
        </v-col>
        <v-col>
          <common-autocomplete-input
            :inputLabel="$_strings.locations.CITY_INPUT_LABEL"
            v-model="form.address.cityId"
            :label="$_strings.locations.CITY_INPUT_LABEL"
            item-text="text"
            item-value="value"
            :items="cityList"
            :readOnly=readonly
          />
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col offset-md="2">
          <common-autocomplete-input
            :inputLabel="$_strings.locations.DISTRICT_INPUT_LABEL"
            v-model="form.address.districtId"
            :label="$_strings.locations.DISTRICT_INPUT_LABEL"
            item-text="text"
            item-value="value"
            :items="districtList"
            :readOnly=readonly
          />
        </v-col>
        <v-col>
          <common-autocomplete-input
            :inputLabel="$_strings.locations.SUBDISTRICT_INPUT_LABEL"
            v-model="form.address.subDistrictId"
            :label="$_strings.locations.SUBDISTRICT_INPUT_LABEL"
            item-text="text"
            item-value="value"
            :items="subDistrictList"
            :readOnly=readonly
          />
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col
          offset-md="2"
          cols="12"
          sm="5"
        >
          <common-text-input
            :inputLabel="$_strings.locations.POSTAL_CODE_INPUT_LABEL"
            v-model="form.address.postalCode"
            :labelWidth="3"
            :readOnly=readonly
          />
        </v-col>
      </v-row>
      <v-row
        no-gutters
        class="ml-3"
      >
        <v-col
          cols="12"
          sm="2"
          class="mt-1"
        >
          <span class="body-2">
            {{$_strings.companyInformation.BILLING_ADDRESS_INPUT_LABEL}}
          </span>
        </v-col>
        <v-col class="mt-n3">
          <v-checkbox
            :disabled=readonly
            v-model="billingAddressSameAsCompanyAddress"
            :label="$_strings.companyInformation.SAME_AS_COMPANY_ADDRESS_CHECKBOX_LABEL"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col offset-md="2">
          <common-text-input
            :inputLabel="$_strings.companyInformation.BILLING_ADDRESS_INPUT_LABEL"
            hideLabel
            v-model="form.billingAddress.address1"
            isTextArea
            :readOnly=readonly

          />
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col offset-md="2">
          <common-autocomplete-input
            :inputLabel="$_strings.locations.PROVINCE_INPUT_LABEL"
            v-model="form.billingAddress.provinceId"
            :label="$_strings.locations.PROVINCE_INPUT_LABEL"
            item-text="text"
            item-value="value"
            :items="provinceList"
            :readOnly=readonly
          />
        </v-col>
        <v-col>
          <common-autocomplete-input
            :inputLabel="$_strings.locations.CITY_INPUT_LABEL"
            v-model="form.billingAddress.cityId"
            :label="$_strings.locations.CITY_INPUT_LABEL"
            item-text="text"
            item-value="value"
            :items="cityListBillingAddress"
            :readOnly=readonly
          />
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col offset-md="2">
          <common-autocomplete-input
            :inputLabel="$_strings.locations.DISTRICT_INPUT_LABEL"
            v-model="form.billingAddress.districtId"
            :label="$_strings.locations.DISTRICT_INPUT_LABEL"
            item-text="text"
            item-value="value"
            :items="districtListBillingAddress"
            :readOnly=readonly
          />
        </v-col>
        <v-col>
          <common-autocomplete-input
            :inputLabel="$_strings.locations.SUBDISTRICT_INPUT_LABEL"
            v-model="form.billingAddress.subDistrictId"
            :label="$_strings.locations.SUBDISTRICT_INPUT_LABEL"
            item-text="text"
            item-value="value"
            :items="subDistrictListBillingAddress"
            :readOnly=readonly
          />
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col
          offset-md="2"
          md="5"
          cols="12"
        >
          <common-text-input
            :inputLabel="$_strings.locations.POSTAL_CODE_INPUT_LABEL"
            v-model="form.billingAddress.postalCode"
            :labelWidth="3"
            :readOnly=readonly
          />
        </v-col>
      </v-row>
      <common-text-input
        :inputLabel="$_strings.companyInformation.NPWP_INPUT_LABEL"
        v-model="form.npwpNo"
        :readOnly=readonly
      />
      <common-text-input
        :inputLabel="$_strings.companyInformation.NPWP_ADDRESS_INPUT_LABEL"
        v-model="form.npwpAddress"
        isTextArea
        :readOnly=readonly
      />
      <common-text-input
        :inputLabel="$_strings.companyInformation.PKP_NO_INPUT_LABEL"
        v-model="form.pkpNo"
        isTextArea
        :readOnly=readonly
      />
      <v-col>
        <v-row>
          <v-col cols="12" sm="2" class="pt-0">
            <p class="body-2 mt-2">
              {{$_strings.companyInformation.COMPANY_PRESIDENT_NAME_INPUT_LABEL}}
            </p>
          </v-col>
          <v-col cols="12" sm="2" class="pt-0">
            <v-select
              hide-details
              outlined
              dense
              v-model="form.presidentDirectorSalutation"
              :items="['Bpk', 'Ibu']"
              :disabled=readonly
            ></v-select>
          </v-col>
          <v-col cols="ma-0 pa-0">
            <common-text-input
              v-model="form.presidentDirector"
              :readOnly=readonly
            />
          </v-col>
        </v-row>
      </v-col>
      <common-text-input
        :inputLabel="$_strings.companyInformation.POSITION_INPUT_LABEL"
        v-model="form.jobTitle"
        :readOnly=readonly
      />
      <common-text-input
        :inputLabel="$_strings.companyInformation.SIUP_NO_INPUT_LABEL"
        v-model="form.siupNo"
        :readOnly=readonly
      />
      <common-text-input
        :inputLabel="$_strings.companyInformation.TDP_NO_INPUT_LABEL"
        v-model="form.tdpNo"
        :readOnly=readonly
      />
      <common-text-input
        :inputLabel="$_strings.companyInformation.COMPANY_EMAIL_INPUT_LABEL"
        v-model="form.email"
        :readOnly=readonly
      />
      <v-row>
        <v-col
          cols="12"
          md="2"
          class="pl-3 mt-2 ml-2"
        >
          <span class="body-2">
            {{$_strings.companyInformation.COMPANY_PHONE_NO_INPUT_LABEL}}
          </span>
        </v-col>
        <v-col cols="12" md="4" class="px-6 px-md-3">
          <span class="body-2">
            Kantor
          </span>
          <phone-number-format
            :placeholder="$_strings.companyInformation.OFFICE_INPUT_LABEL"
            v-model="form.phoneNo"
            outlined
            dense
            :disabled="readonly"
            :required="readonly ? false : true"
          ></phone-number-format>
        </v-col>
        <v-col cols="12" md="5" class="px-6 px-md-0">
          <span class="body-2">
            Fax
          </span>
          <phone-number-format
            :placeholder="$_strings.companyInformation.FAX_INPUT_LABEL"
            v-model="form.faxNo"
            :disabled="readonly"
            class="mt-1"
            outlined
            dense
            :required="readonly ? false : true"
          ></phone-number-format>
        </v-col>
      </v-row>
      <common-text-input
        :inputLabel="$_strings.companyInformation.COMPANY_SERVICE_INPUT_LABEL"
        v-model="form.companyService"
        :readOnly=readonly
      />
      <common-date-input
        :inputLabel="$_strings.companyInformation.COMPANY_ESTABLISHED_YEAR_INPUT_LABEL"
        v-model="form.establishDate"
        :readOnly=readonly
      />
      <common-text-input
        :inputLabel="$_strings.companyInformation.COMPANY_ADDITIONAL_INFO_INPUT_LABEL"
        v-model="form.notes"
        :readOnly=readonly
      />
    </v-form>
  </v-container>
</template>

<script>
export default {
  name: 'company-information',
  props: {
    readonly: {
      type: Boolean,
      default: () => true,
    },
    form: {
      type: Object,
      default: () => {},
    },
    fetchCompanyInformation: {
      type: Function,
    },
  },
  data() {
    return {
      isLoading: false,
      valid: false,
      provinceSearch: null,
      provinceLoading: false,
      provinceList: [],
      citySearch: null,
      cityLoading: false,
      cityList: [],
      cityBillingAddressLoading: false,
      cityListBillingAddress: [],
      districtSearch: null,
      districtLoading: false,
      districtList: [],
      districtBillingAddressLoading: false,
      districtListBillingAddress: [],
      subDistrictSearch: null,
      subDistrictLoading: false,
      subDistrictList: [],
      subDistrictBillingAddressLoading: false,
      subDistrictListBillingAddress: [],
      isAgree: false,
      validAbbreviation: false,
      oldAbbreviation: null,
      rules: {
        abbreviation: [
          (v) => !!v || this.$_strings.messages.validation.REQUIRED('Alias/Inisial'),
          (value) => {
            const pattern = /^[a-z ,.'-]+$/i;
            if (value.length < 2 || value.length > 5) {
              return 'Min 2 karakter Maks 5';
            }
            if (value !== value.toUpperCase()) {
              return 'Wajib huruf kapital';
            }
            return pattern.test(value) || this.$_strings.messages.validation.ALIAS;
          },
        ],
      },
    };
  },
  computed: {
    path() {
      const [,,, path] = this.$route.path.split('/');
      return path;
    },
    billingAddressSameAsCompanyAddress: {
      get() {
        return (
          (this.form.address.address1 === this.form.billingAddress.address1)
          && (this.form.address.subDistrictId === this.form.billingAddress.subDistrictId)
          && (this.form.address.districtId === this.form.billingAddress.districtId)
          && (this.form.address.cityId === this.form.billingAddress.cityId)
          && (this.form.address.provinceId === this.form.billingAddress.provinceId)
          && (this.form.address.postalCode === this.form.billingAddress.postalCode)
        );
      },
      set(bool) {
        if (bool) {
          this.form.billingAddress.address1 = this.form.address.address1;
          this.form.billingAddress.subDistrictId = this.form.address.subDistrictId;
          this.form.billingAddress.districtId = this.form.address.districtId;
          this.form.billingAddress.cityId = this.form.address.cityId;
          this.form.billingAddress.provinceId = this.form.address.provinceId;
          this.form.billingAddress.postalCode = this.form.address.postalCode;
        } else {
          this.form.billingAddress.address1 = null;
          this.form.billingAddress.subDistrictId = null;
          this.form.billingAddress.districtId = null;
          this.form.billingAddress.cityId = null;
          this.form.billingAddress.provinceId = null;
          this.form.billingAddress.postalCode = null;
        }
      },
    },
  },
  methods: {
    async fetchData() {
      try {
        this.isLoading = true;
        await this.fetchCompanyInformation();
        this.oldAbbreviation = this.form.abbreviation;
        this.setSelectListItems();
      } finally {
        this.isLoading = false;
      }
    },
    setSelectListItems() {
      const { address, billingAddress } = this.form;
      this.provinceList = [{
        text: address.province,
        value: address.provinceId,
      }];
      this.cityList = [{
        text: address.city,
        value: address.cityId,
      }];
      this.districtList = [{
        text: address.district,
        value: address.districtId,
      }];
      this.subDistrictList = [{
        text: address.subDistrict,
        value: address.subDistrictId,
      }];
      // Billing Address
      this.provinceListBillingAddress = [{
        text: billingAddress.province,
        value: billingAddress.provinceId,
      }];
      this.cityListBillingAddress = [{
        text: billingAddress.city,
        value: billingAddress.cityId,
      }];
      this.districtListBillingAddress = [{
        text: billingAddress.district,
        value: billingAddress.districtId,
      }];
      this.subDistrictListBillingAddress = [{
        text: billingAddress.subDistrict,
        value: billingAddress.subDistrictId,
      }];
    },
    async updateAbbreviation() {
      const { companyId } = this.$route.params;
      const [,, updateBy] = this.$route.path.split('/');
      let service;
      if (updateBy === 'customer') service = this.$_services.masterShipper.updateMasterShipper;
      if (updateBy === 'rekanan') service = this.$_services.masterPatner.updateMasterPartner;
      try {
        this.$root.$loading.show();
        await service(companyId, this.form.abbreviation.toUpperCase());
        this.form.abbreviation = this.form.abbreviation.toUpperCase();
        this.oldAbbreviation = this.form.abbreviation.toUpperCase();
        this.$dialog.notify.success('Berhasil memperbarui');
      } finally {
        this.$root.$loading.hide();
      }
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>
